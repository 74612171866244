<!-- 云平台迎新免登陆收费页面 -->
<template>
  <div id="qrcodeYingXin">
    <div class="head">
      <!-- 顶部NavBar 开始 -->
      <van-nav-bar
        :title="$t('迎新信息登记')"
        style="background-color: rgba(255,255,255,0);"
      />
    </div>
    <div
      class="outboxstyle"
    >
      <div class="boxstyle">
        <div class="box-form">
          <van-form>
            <van-cell>
              <van-field
                v-model="form.idserial"
                type="text"
                label="学生学号"
                input-align="right"
                placeholder="请输入学生学号"
                @blur="studentIdserial"
              />
              <van-field
                v-model="form.name"
                type="text"
                input-align="right"
                label="学生姓名"
                placeholder="请输入学生姓名"
              />
              <van-field
                readonly
                clickable
                name="picker"
                :value="form.showSex"
                input-align="right"
                label="学生性别"
                placeholder="请选择学生性别"
                @click="sexShow = true"
              />
              <van-popup v-model="sexShow" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="sexList"
                  @confirm="onConfirmSex"
                  @cancel="sexShow = false"
                />
              </van-popup>
              <van-field
                readonly
                clickable
                name="picker"
                :value="form.showIdentityType"
                input-align="right"
                label="学生学部"
                placeholder="请选择学生学部"
                @click="identityTypeShow = true"
              />
              <van-popup v-model="identityTypeShow" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="identityList"
                  @confirm="onConfirmIdentity"
                  @cancel="identityTypeShow = false"
                />
              </van-popup>
              <van-field
                readonly
                clickable
                name="picker"
                :value="form.showSchoolGrade"
                input-align="right"
                label="学生年级"
                placeholder="请选择学生年级"
                @click="schoolGradeShow = true"
              />
              <van-popup v-model="schoolGradeShow" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="schoolGradeList"
                  @confirm="onConfirmGrade"
                  @cancel="schoolGradeShow = false"
                />
              </van-popup>
              <van-field
                readonly
                clickable
                name="picker"
                :value="form.showStudentIdentity"
                input-align="right"
                label="学生类型"
                placeholder="请选择学生类型"
                @click="studentTypeShow = true"
              />
              <van-popup v-model="studentTypeShow" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="studentList"
                  @confirm="onConfirmStudent"
                  @cancel="studentTypeShow = false"
                />
              </van-popup>
            </van-cell>
            <div class="bottom">
              <van-button type="info" size="large" class="bottombutton" native-type="submit" :loading="btnNextLoading" @click="saveUserGetStandNameList">{{ $t('提交') }}</van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
    <!-- 回到顶部按钮 -->
    <v-top />
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import Cookies from 'js-cookie'
import { yingXinSaveUser, getTokenByIdeserialAndSchoolCode } from '@/api/project'
import { getDictDatasByTypesNotToken } from '@/api/dict'
export default {
  name: 'YingXinQrcode',
  data() {
    return {
      form: {
        idserial: '',
        name: '',
        sex: '',
        showSex: '',
        schoolCode: '',
        identityType: '',
        showIdentityType: '',
        schoolGrade: '',
        showSchoolGrade: '',
        dataSource: 'PAY',
        identityType1: '',
        showStudentIdentity: ''
      },
      identityTypeShow: false,
      schoolGradeShow: false,
      studentTypeShow: false,
      sexShow: false,
      btnNextLoading: false,
      identityList: [],
      schoolGradeList: [],
      schoolGrade_List: [],
      sexList: [],
      paymentList: [],
      studentList: []
    }
  },
  mounted() {
    this.getDictDatasByTypesNotToken()
    window.localStorage.setItem('openId', this.$route.query.openId)
    window.localStorage.setItem('schoolCode', this.$route.query.schoolCode)
    if (this.$route.query.token !== undefined) {
      var params = { token: this.$route.query.token }
      this.$store.dispatch('user/qrcodeLogin', params).then(() => {
        this.$router.push({ path: this.redirect || '/qrcodeYingXin?schoolCode=' + this.$route.query.schoolCode })
      })
    }
  },
  methods: {
    // 获取字典
    getDictDatasByTypesNotToken() {
      getDictDatasByTypesNotToken('SCHOOL_GRADE,IDENTITY_TYPE,SEX,IDENTITY_TYPE1', this.$route.query.schoolCode, 'PAY').then(response => {
        const sexList = response.data.SEX
        for (let i = 0; i < sexList.length; i++) {
          this.$set(sexList[i], 'text', sexList[i].value)
        }
        const identityList = response.data.IDENTITY_TYPE
        for (let i = 0; i < identityList.length; i++) {
          this.$set(identityList[i], 'text', identityList[i].value)
        }
        const schoolGradeList = response.data.SCHOOL_GRADE
        for (let i = 0; i < schoolGradeList.length; i++) {
          this.$set(schoolGradeList[i], 'text', schoolGradeList[i].value)
        }
        const studentList = response.data.IDENTITY_TYPE1
        for (let i = 0; i < studentList.length; i++) {
          this.$set(studentList[i], 'text', studentList[i].value)
        }
        this.sexList = sexList
        this.identityList = identityList
        this.schoolGrade_List = schoolGradeList
        this.studentList = studentList
      })
    },
    // 字典表性别
    onConfirmSex(val) {
      this.form.showSex = val.value
      this.form.sex = val.key
      this.sexShow = false
    },
    // 字典表学部（身份类别）
    onConfirmIdentity(val) {
      this.form.showIdentityType = val.value
      this.form.identityType = val.key
      this.identityTypeShow = false
      this.schoolGradeList = []
      this.form.showSchoolGrade = ''
      this.form.schoolGrade = ''
      for (let i = 0; i < this.schoolGrade_List.length; i++) {
        var type = this.schoolGrade_List[i].key.split('_')[0]
        if (val.key === type) {
          this.schoolGradeList.push(this.schoolGrade_List[i])
        }
      }
    },
    // 字典表年级
    onConfirmGrade(val) {
      this.form.showSchoolGrade = val.value
      this.form.schoolGrade = val.key
      this.schoolGradeShow = false
    },
    // 字典表学生类别（身份类别1）
    onConfirmStudent(val) {
      this.form.showStudentIdentity = val.value
      this.form.identityType1 = val.key
      this.studentTypeShow = false
    },
    studentIdserial() {
      if (!this.form.studentIdserial) {
        return false
      } else {
        return true
      }
    },
    // 下一步提交
    saveUserGetStandNameList() {
      if (this.form.idserial === null || this.form.idserial === '') {
        Toast.fail('请输入学号')
        return
      }
      if (this.form.name === null || this.form.name === '') {
        Toast.fail('请输入姓名')
        return
      }
      if (this.form.sex === null || this.form.sex === '') {
        Toast.fail('请输入性别')
        return
      }
      if (this.form.identityType === null || this.form.identityType === '') {
        Toast.fail('请输入学部')
        return
      }
      if (this.form.schoolGrade === null || this.form.schoolGrade === '') {
        Toast.fail('请输入年级')
        return
      }
      Dialog.alert({
        title: '提示',
        message: '学生信息提交后无法修改，请谨慎填写！',
        showCancelButton: true
      }).then(() => {
        this.btnNextLoading = true
        this.form.schoolCode = this.$route.query.schoolCode
        yingXinSaveUser(this.form).then(response => {
          this.paymentList = response.data
          getTokenByIdeserialAndSchoolCode(this.$route.query.schoolCode, this.form.idserial).then(res => {
            Cookies.set('qy_reimbursement_token', res.data)
            this.$store.dispatch('user/refreshToken', res.data)
            window.localStorage.setItem('accountNum', this.form.idserial)
            this.$router.push({ path: '/standardPay' })
            this.btnNextLoading = false
          }).catch(error => {
            Toast.fail(error.message)
            this.btnNextLoading = false
          })
        }).catch(error => {
          Toast.fail(error.message)
          this.btnNextLoading = false
        })
      })
    }
  }
}
</script>
<style scoped lang="scss">
.red{
    .van-cell__value{
        color:red;
        span{
            color: red;
        }
    }
     .van-field__control--right{
        color:red
    }
}
#qrcodeYingXin {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 100px;
    width: 100%;
    background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 80px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  padding: 10px;
  margin: 10px 5px 10px 5px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.boxstylemoney{
  margin: 10px 15px 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px 20px 10px 20px;
  }
}
.lastbattry{
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span{
    color: #1989fa;
    float: right;
  }
}
.paymonry{
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span{
    color: red;
    float: right;
  }
}
.picker{
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 10px 20px;
  width: 90%;
  padding: 5px;
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 80px;
  z-index: 99;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
.van-cell__title {
    span {
      height: 44px;
      line-height: 44px;
    }
}
.boxstyle .van-cell {
    padding: 18px 20px;
}
.boxstylemoney {
  .van-cell {
      padding: 18px 20px !important;
  }
}
</style>
<style lang="scss">
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
#qrcodeYingXin .van-field__label {
    line-height: 24px !important;
    height: 24px !important;
    width: 3.4rem;
}
#qrcodeYingXin .van-field__value {
    line-height: 24px !important;
    height: 24px !important;
}
.text {
  color: #ffffff;
  font-size: 15px;
  font-family: PingFang SC;
  line-height: 14px;
}
</style>
